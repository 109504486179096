<template lang="pug">
  v-dialog(
    v-model="openDialog"
    persistent
    fullscreen
    eager
    transition="dialog-bottom-transition"
  )
    v-toolbar(
      :color="toolbarColor"
      dark
    )
      v-icon(v-html="toolbarIcon").mr-2
      span Voucher Info
      v-spacer
      v-btn(
        text
        @click="openDialog = false"
      )
        v-icon mdi-close
    v-card
      v-container(fluid)
        v-row
          v-col(cols="3")
            v-row
              v-col(cols="4")
                base-card(title="Voucher" :text="data.voucher_no")
              v-col(cols="4")
                base-card(title="Date" :text="data.date.dateFormat()")
              v-col(cols="4")
                base-card(title="Status" :text="status")
              v-col(cols="6")
                base-card(title="Project Code" :text="projectCode")
              v-col(cols="6")
                base-card(title="Budget Code" :text="budgetCode")
              v-col(cols="6")
                base-card(title="Created By" :text="requestor")
              v-col(cols="6")
                base-card(title="Paid By" :text="paidBy")
              v-col(cols="6")
                base-card(title="Cost Center" :text="costCenter")
              v-col(cols="6")
                base-card(title="Pay To" :text="payTo")
              v-col(cols="12")
                base-card(title="Address" :text="data.address")
              v-col(cols="12" v-if="!approvedOrRejected")
                v-btn.mr-2(
                  color="green"
                  dense
                  small
                  dark
                  :loading="approving"
                  @click="$emit('approve', data.id)"
                )
                  span Approve
                v-btn(
                  color="red"
                  dense
                  small
                  dark
                  :loading="rejecting"
                  @click="$emit('reject', data.id)"
                )
                  span Reject
          v-col(cols="5")
            v-simple-table
              template(v-slot:default)
                thead
                  tr
                    th Account Code
                    th Reason
                    th.text-right Amount
                tbody
                  tr(v-for="item in data.details" :key="item.id")
                    td
                      div(v-if="item.account_code")
                        span.primary--text.mr-2 {{ item.account_code.account_code }}
                        small.blue-grey--text {{ item.account_code.description }}
                    td {{ item.reason }}
                    th.text-right {{ item.amount.phpFormat() }}
                  tr
                    th(colspan="2") Total
                    th.text-right {{ totalAmount.phpFormat() }}
          v-col(cols="4")
            v-simple-table
              template(v-slot:default)
                thead
                  tr
                    th Acct Code
                    th.text-right Disbursed Amount
                    th.text-right Amount
                    th.text-right Balance
                tbody
                  tr(v-for="item in data.account_codes" :key="item.id")
                    td
                      div(v-if="item.account_code")
                        span.primary--text.mr-2 {{ item.account_code.account_code }}
                        small.blue-grey--text {{ item.account_code.description }}
                    th.text-right {{ Number(item.disbursed_amount).phpFormat() }}
                    th.text-right {{ item.amount.phpFormat() }}
                    th.text-right(:class="(item.balance < 0 && 'red--text')") {{ item.balance.phpFormat() }}
                  tr(v-if="data.revolving_fund")
                    th --.--
                    th.text-right {{ revolvingFundDisbursedAmount.phpFormat() }}
                    th.text-right {{ (0).phpFormat() }}
                    th.text-right.red--text -{{ revolvingFundDisbursedAmount.phpFormat() }}
                  tr
                    th Total
                    th.text-right {{ totalDisbursedAmount.phpFormat() }}
                    th.text-right {{ totalAmount.phpFormat() }}
                    th.text-right {{ totalBalance.phpFormat() }}
</template>
<script>

function codeAndDescriptionTemplate (code, description) {
  return `<span class="primary--text mr-2">${code}</span><small class="blue-grey--text">${description}</small>`
}

export default {
  name: 'ShowVoucher',
  props: ['value', 'data', 'approving', 'rejecting'],
  data () {
    return {

    }
  },
  computed: {
    toolbarIcon () {
      if (this.data.voucher_status_id === 'AP') return 'mdi-check'
      if (this.data.voucher_status_id === 'RJ') return 'mdi-alert'
      return 'mdi-info-outlined'
    },
    toolbarColor () {
      if (this.data.voucher_status_id === 'AP') return 'success'
      if (this.data.voucher_status_id === 'RJ') return 'error'
      return 'primary'
    },
    approvedOrRejected () {
      const status = this.data.voucher_status_id
      return ['AP', 'RJ'].includes(status)
    },
    approvedRejectedBy () {
      if (!this.approvedOrRejected) return null
      return this.data.approved_rejected_by.name
    },
    requestor () {
      if (!this.data.created_by) return null
      return this.data.created_by.name
    },
    costCenter () {
      if (!this.data.cost_center) return null
      const { code, description } = this.data.cost_center
      return codeAndDescriptionTemplate(code, description)
    },
    paidBy () {
      if (!this.data.paid_by) return null
      const { code, description } = this.data.paid_by
      return codeAndDescriptionTemplate(code, description)
    },
    payTo () {
      if (!this.data.pay_to) return null
      const { id, name } = this.data.pay_to
      return codeAndDescriptionTemplate(id, name)
    },
    totalDisbursedAmount () {
      return this.data.account_codes.reduce((total, item) => Number(item.disbursed_amount) + total, 0) + this.revolvingFundDisbursedAmount
    },
    totalBalance () {
      return this.data.account_codes.reduce((total, item) => item.balance + total, 0) - this.revolvingFundDisbursedAmount
    },
    totalAmount () {
      if (!this.data.details) return 0
      return this.data.details.reduce((total, item) => total + item.amount, 0)
    },
    revolvingFundDisbursedAmount () {
      return this.data?.revolving_fund?.disbursed_amount || 0
    },
    status () {
      const template = (c, a, b) => `<span class="${c}--text mr-2">${a}</span><small class="blue-grey--text">${b}</small>`
      const dataStatus = this.data.status
      if (dataStatus.id === 'AP') return template('success', dataStatus.description, this.approvedRejectedBy)
      if (dataStatus.id === 'RJ') return template('error', dataStatus.description, this.approvedRejectedBy)
      return `<span class="primary--text">${dataStatus.description}</span>`
    },
    projectCode () {
      if (!this.data.project_code) return null
      const pc = this.data.project_code
      return `
        <span class="primary--text">${pc.project_code}</span><br>
        <small class="blue-grey--text">${pc.description}</small>`
    },
    budgetCode () {
      if (!this.data.budget_code) return null
      const bc = this.data.budget_code
      return `
        <span class="primary--text">${bc.budget_code}</span><br>
        <small class="blue-grey--text">${bc.description}</small>`
    },
    openDialog: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {

  },
}
</script>